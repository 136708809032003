// Core
import { AxiosError} from "axios";
import { HttpClient } from "./http.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import { ErrorResponseModel } from "@/models/interface/http.interface";
import {
  LoginRequestModel,
  LoginResponseModel,
  RefreshtokenRequestModel,
  ForgotRequestModel
} from "@/models/interface/auth.interface";
import querystring from 'querystring'

type PayloadStringify<T> = T & Record<string, any>

export class AuthService extends HttpClient {
  public constructor() {
    super();

    this.signin = this.signin.bind(this);
  }

  public signin(payload: LoginRequestModel): Promise<LoginResponseModel> {
    const newPayloadStringify: PayloadStringify<LoginRequestModel> = payload
    return this.post<LoginResponseModel, LoginRequestModel>(Api.LOGIN, querystring.stringify(newPayloadStringify), { noAuthorization: true, headers: { "Content-Type": "application/x-www-form-urlencoded" } })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public refreshToken(payload: RefreshtokenRequestModel): Promise<LoginResponseModel> {
    const newPayloadStringify: PayloadStringify<RefreshtokenRequestModel> = payload
    return this.post<LoginResponseModel, RefreshtokenRequestModel>(Api.LOGIN, querystring.stringify(newPayloadStringify), { noAuthorization: true, headers: { "Content-Type": "application/x-www-form-urlencoded" } })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public forgotPassword(payload: ForgotRequestModel): Promise<boolean> {    
    return this.put<boolean, ForgotRequestModel>(Api.ForgotPassword, payload, { noAuthorization: true })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}
export const authService = new AuthService();
