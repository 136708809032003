var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-content",
    { staticClass: "custom-layout-content" },
    [
      _c(
        "a-row",
        {
          staticStyle: { height: "100vh" },
          attrs: { type: "flex", justify: "center" }
        },
        [
          _c(
            "a-card",
            { staticClass: "custom-card-container" },
            [
              _c("div", { staticClass: "custom-card-title" }, [
                _vm._v("Forgot Password")
              ]),
              _c("div", { staticClass: "custom-card-description" }, [
                _vm._v(
                  "Please enter your email, our system will immediately send you a new password!"
                )
              ]),
              _c(
                "a-form",
                {
                  attrs: { form: _vm.form, layout: "vertical" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.formRules.email.label,
                        hasFeedback: true
                      }
                    },
                    [
                      _c(
                        "a-input",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.email.decorator,
                              expression: "formRules.email.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.email.name,
                            placeholder: _vm.formRules.email.placeholder,
                            autocomplete: "off"
                          }
                        },
                        [
                          _c("a-icon", {
                            staticStyle: { color: "rgba(0, 0, 0, 0.25)" },
                            attrs: { slot: "prefix", type: "user" },
                            slot: "prefix"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "submit-button",
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        loading: _vm.isFormSubmitted
                      }
                    },
                    [_vm._v(" Reset ")]
                  ),
                  _c(
                    "label",
                    { staticClass: "footer-card" },
                    [
                      _vm._v(" Have an account ? "),
                      _c("router-link", { attrs: { to: "./signin" } }, [
                        _vm._v("Sign In")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }